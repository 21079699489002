<template>
  <v-container class="div-responsive px-0 py-0">
    <div>
      <v-img @click="$router.push({name: 'Login'})" class="mx-auto mb-15" src="@/assets/img/logos/auth-logo.png"
             :height="$vuetify.breakpoint.smAndUp? '196px': '147px'" contain style="cursor: pointer;"></v-img>
      <p class="auth-title mb-2">Recuperar contraseña</p>
      <v-form class="recovery-password-form" @submit.prevent="recuperarPassword" ref="form">
        <v-container fluid class="mt-0 px-0">
          <v-row>

            <v-col cols="12" class="py-0">
              <p class="mx-auto text-center auth-text" style="max-width: 312px;">
                Ingresa un correo electrónico al que tengas acceso para que podamos enviarte un link y puedas
                reestablecer tu contraseña
              </p>
            </v-col>

            <v-col cols="12" class="">
              <base-text-field
                label="Correo"
                v-model="email"
                :rules="[rules.email, rules.required]"
              ></base-text-field>
            </v-col>

            <v-col cols="12 mt-5">
              <base-button class="full-width" type="submit">Continuar</base-button>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </div>

    <ConfirmModal ref="authConfirm"/>
    <LoadingModal v-model="loading"></LoadingModal>

  </v-container>
</template>

<script>
import LoadingModal from '@/components/emergentesGlobales/LoadingModal'
import { email, required } from '@/utils/rules'
import axios from 'axios'
import ConfirmModal from '@/components/auth/ConfirmModal'
import BaseButton from '@/components/auth/BaseButton'
import BaseTextField from '@/components/auth/BaseTextField'

export default {
  components: {
    LoadingModal,
    ConfirmModal,
    BaseButton,
    BaseTextField
  },
  data () {
    return {
      showAlert: false,
      email: '',
      loading: false,
      rules: {
        required,
        email
      }
    }
  },
  methods: {
    async recuperarPassword () {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          await axios.post('api/rest-auth/password/reset/', {
            email: this.email,
            current_site: window.location.origin
          })
          await this.$refs.authConfirm.open({
            type: 'feedback',
            title: 'Reestablecer contraseña',
            message: 'Revisa tu correo electrónico e ingresa al link que te enviamos para reestablecer tu contraseña',
            acceptText: 'Aceptar'
          })
          this.$router.push({ name: 'Login' })
        } catch (e) {
          await this.$refs.authConfirm.open({
            type: 'error',
            title: '¡Ups!',
            message: 'Ha ocurrido un error. Inténtelo de nuevo en unos minutos',
            acceptText: 'Confirmar'
          })
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style scoped>
.recovery-password-form {
  max-width: 448px;
  margin: auto;
}
</style>
