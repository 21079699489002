<template>
  <div class="w-100 h-100 d-flex justify-center align-center">
    <componentRecoverPassword />
  </div>
</template>

<script>
import componentRecoverPassword from '@/components/login/RecoverPassword.vue'

export default {
  name: 'RecoverPassword',
  components: {
    componentRecoverPassword
  }
}
</script>
